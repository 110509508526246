export const TABLE_COMPLETED = 'completed';
export const TABLE_AWAITING_SIGNATURES = 'awaiting_signatures';

export const STEP_DETAILS = 'details';
export const STEP_TENANTS = 'tenants';
export const STEP_SUMMARY = 'summary';
export const STEP_SEND = 'send';

export const TENANT_TYPES = {
  STUDENT: 'Student',
  NON_STUDENT: 'Non-student',
  MIXED: 'Mixed',
};

export const UTILITY_GAS = 'Gas';
export const UTILITY_ELECTRICITY = 'Electricity';
export const UTILITY_INTERNET = 'Internet';
export const UTILITY_WATER = 'Water';
export const UTILITY_TV_LICENCE = 'TV Licence';

export const DEFAULT_UTILITIES = [
  UTILITY_GAS,
  UTILITY_ELECTRICITY,
  UTILITY_INTERNET,
  UTILITY_WATER,
  UTILITY_TV_LICENCE,
];

export const VALID_UTILITY_COMBINATIONS = [
  DEFAULT_UTILITIES, // Gas, Electricity, Internet, Water, TV Licence
  [UTILITY_GAS, UTILITY_ELECTRICITY, UTILITY_INTERNET, UTILITY_WATER],
  [UTILITY_GAS, UTILITY_ELECTRICITY, UTILITY_INTERNET, UTILITY_TV_LICENCE],
  [UTILITY_GAS, UTILITY_ELECTRICITY, UTILITY_WATER, UTILITY_TV_LICENCE],
  [UTILITY_GAS, UTILITY_ELECTRICITY, UTILITY_WATER],
  [UTILITY_GAS, UTILITY_ELECTRICITY, UTILITY_TV_LICENCE],
];

export const UTILITIES_OPTION2 = [
  UTILITY_GAS,
  UTILITY_ELECTRICITY,
  UTILITY_WATER,
  UTILITY_TV_LICENCE,
];

export const UTILITIES_OPTION3 = [
  UTILITY_GAS,
  UTILITY_ELECTRICITY,
  UTILITY_INTERNET,
  UTILITY_WATER,
];

export const UTILITIES_OPTION4 = [
  UTILITY_GAS,
  UTILITY_ELECTRICITY,
  UTILITY_WATER,
];

export const UTILITIES_OPTION5 = [
  UTILITY_GAS,
  UTILITY_ELECTRICITY,
  UTILITY_INTERNET,
  UTILITY_TV_LICENCE,
];

export const UTILITIES_OPTION6 = [
  UTILITY_GAS,
  UTILITY_ELECTRICITY,
  UTILITY_TV_LICENCE,
];

export const UTILITIES_ICONS = {
  [UTILITY_GAS]: 'images/icons/22-icon-gas.png',
  [UTILITY_ELECTRICITY]: 'images/icons/22-icon-electricity.png',
  [UTILITY_INTERNET]: 'images/icons/22-icon-internet.png',
  [UTILITY_WATER]: 'images/icons/22-icon-water.png',
  [UTILITY_TV_LICENCE]: 'images/icons/22-icon-tv-licence.png',
};

export const MODAL_VOID = 'modal_void';
export const MODAL_SEND_REMINDERS = 'modal_send_reminders';
export const MODAL_TENANT = 'modal_tenant';
export const MODAL_UPLOAD = 'modal_upload';
export const MODAL_DELETE = 'modal_delete';

export const MODAL_TYPES = [
  MODAL_VOID,
  MODAL_SEND_REMINDERS,
  MODAL_TENANT,
  MODAL_UPLOAD,
  MODAL_DELETE,
];
